import { getJSONData } from '../util/domutils'; // eslint-disable-line import/named
import { handleSizeConversion } from '../../../../scripts/helpers/sizeHelpers';

const SELECTORS = {
  CONVERSION_DETAILS_SOURCE: '[data-size-conversions]',
  LABEL_CONVERSIONS: '[data-convert-label]',
  VALUE_CONVERSIONS: '[data-convert-value]',
};

export default () => {
  const conversionDetailsSource = document.querySelector(SELECTORS.CONVERSION_DETAILS_SOURCE);
  const isShoe = document.getElementsByName('isShoeProduct').length > 0
    ? document.getElementsByName('isShoeProduct')[0] : null;

  if (conversionDetailsSource) {
    let conversionDetails = getJSONData(conversionDetailsSource, 'sizeConversions', null);
    if (isShoe && isShoe.value === 'true') { // this logic added for shoe size conversion.
      conversionDetails = getJSONData(conversionDetailsSource, 'shoeSizeConversions', null);
    }

    if (conversionDetails) {
      const labelConversions = document.querySelectorAll(SELECTORS.LABEL_CONVERSIONS);
      const valueConversions = document.querySelectorAll(SELECTORS.VALUE_CONVERSIONS);

      labelConversions.forEach((conversionEl) => {
        const isRemoveSizePrefix = conversionEl && conversionEl.classList.contains('remove-size-prefix');
        // eslint-disable-next-line max-len
        let targetText = isRemoveSizePrefix ? `${conversionEl.dataset.convertLabel}` : `${conversionDetails.prefix} ${conversionEl.dataset.convertLabel}`;

        if (conversionEl.dataset.convertLabel.indexOf('{0}') >= 0) {
          targetText = conversionEl.dataset.convertLabel.replace('{0}', conversionDetails.prefix);
        }

        conversionEl.innerText = targetText;
      });

      valueConversions.forEach((conversionEl) => {
        const baseValue = conversionEl.dataset.convertValue;
        // add some logic for shoe size conversion for PLP.
        const sibling = conversionEl.nextElementSibling;
        if (sibling && sibling.getAttribute('name') === 'isShoeProduct' && sibling.value === 'true') {
          conversionDetails = getJSONData(conversionDetailsSource, 'shoeSizeConversions', null);
        }
        conversionEl.innerText = handleSizeConversion(
          baseValue,
          conversionDetails.sizes.default,
          conversionDetails.sizes.current,
          window.beesfra && window.beesfra.config && window.beesfra.config.sizeConversionZeroPad,
        );
      });
    }
  }
};
